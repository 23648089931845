import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import PrivateRoute from './components/auth/PrivateRoute';
import Login from './pages/Auth/Login';
import Home from './pages/Home/Home';
import Orders from './pages/Orders/Orders';
import Support from './pages/Support/Support';
import SupportChat from './pages/SupportChat/SupportChat';
import User from './pages/User/User';
import Users from './pages/Users/Users';


function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route exact path='/' element={<PrivateRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/support" element={<Support />} />
          <Route path="/support/chat/:chatID" element={<SupportChat />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/users" element={<Users />} />
          <Route path="/user/:userID" element={<User />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;


