import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    handleLogin: async (username, password) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/login/`, {
                username,
                password,
            });

            localStorage.setItem('token', response.data.access_token);

            return true;

        } catch (error) {
            return false;
        }
    },
};

export default api;