import React, { useState } from 'react';
import styles from './StoreAndProducts.module.css'; // Импорт модуля CSS

const StoreInfo = ({ store }) => (
    <div className={styles.storeInfo}>
        <div>
            <h2>{store.name}</h2>
            <img src={store.picture_url} alt={store.name} />
        </div>
        <div>
            <p>Описание: {store.description}</p>
            <p>Ссылка: <a href={store.href} target="_blank" rel="noopener noreferrer">{store.href}</a></p>
        </div>
    </div>
);

const ProductCard = ({ card }) => (
    <div className={styles.productCard}>
        <h4>{card.name}</h4>
        <p>{card.description}</p>
        <p>Цена: {card.total_price} Руб</p>
        <p>Остаток: {card.stock}</p>
        <div className={styles.photos}>
            {card.photos.map(photo => (
                <div key={photo.url} className={styles.photo}><img src={photo.url} alt={card.name} className={styles.photo} /></div>
            ))}
        </div>
    </div>
);

const ProductInfo = ({ product }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.productInfo}>
            <h3 onClick={toggleAccordion} className={styles.toggleBtn}>
                {product.product_id} ({isOpen ? '-' : '+'})
            </h3>
            {isOpen && (
                <div className={styles.productCards}>
                    {product.product_cards.map(card => (
                        <ProductCard key={card.name} card={card} />
                    ))}
                </div>
            )}
        </div>
    );
};

const StoreAndProducts = ({ stores, products }) => (
    <div className={styles.storeAndProducts}>
        <div className={styles.stores}>
            {stores.map(store => (
                <StoreInfo key={store.id} store={store} />
            ))}
        </div>
        <div className={styles.products}>
            {products.map(productGroup => (
                <div key={productGroup.store} style={{ width: '100%' }}>
                    <h2>Товары магазина {productGroup.store_name} ({productGroup.store})</h2>
                    {productGroup.products.map(product => (
                        <ProductInfo key={product.product_id} product={product} />
                    ))}
                </div>
            ))}
        </div>
    </div>
);

export default StoreAndProducts;
