import moment from 'moment';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Table.module.css';
import eye from '../assets/eye.svg'

function Table({ data }) {

    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(columnName);
            setSortOrder('asc');
        }
    };

    // Функция для сортировки данных
    const sortedData = [...data].sort((a, b) => {
        const columnA = a[sortBy];
        const columnB = b[sortBy];
        // Выполняем сортировку в зависимости от типа данных в столбце
        if (typeof columnA === 'string') {
            // Сортировка для строк
            try {
                return sortOrder === 'asc' ? columnA.localeCompare(columnB) : columnB.localeCompare(columnA);
            } catch {
                return 0;
            }
        } else if (typeof columnA === 'number') {
            // Сортировка для чисел
            return sortOrder === 'asc' ? columnA - columnB : columnB - columnA;
        } else if (typeof columnA === 'object' && columnA instanceof Date) {
            // Сортировка для дат
            return sortOrder === 'asc' ? columnA - columnB : columnB - columnA;
        } else if (typeof columnA === 'boolean') {
            return sortOrder === 'asc' ? (columnA - columnB) : (columnB - columnA);
        } else {
            // Если тип данных не определен, возвращаем 0 (без изменения порядка)
            return 0;
        }
    });

    return (
        <table className={styles.usersTable}>
            <thead>
                <tr>
                    <th></th>
                    <th onClick={() => handleSort('username')} style={sortBy === 'username' ? { textDecoration: 'underline' } : null}>Имя пользователя</th>
                    <th onClick={() => handleSort('created_at')} style={sortBy === 'created_at' ? { textDecoration: 'underline' } : null}>Дата регистрации</th>
                    <th>Кол-во магазинов</th>
                    <th onClick={() => handleSort('email')} style={sortBy === 'email' ? { textDecoration: 'underline' } : null}>Email</th>
                    <th onClick={() => handleSort('is_company')} style={sortBy === 'is_company' ? { textDecoration: 'underline' } : null}>Тип</th>
                    {/* <th onClick={() => handleSort('tg_username')} style={sortBy === 'tg_username' ? { textDecoration: 'underline' } : null}>Телеграмм</th> */}
                    <th onClick={() => handleSort('is_banned')} style={sortBy === 'is_banned' ? { textDecoration: 'underline' } : null}>Заблокирован</th>
                    <th>Удален</th>
                </tr>
            </thead>
            <tbody>
                {sortedData ? sortedData.map((row, index) => (
                    <tr key={index} >
                        {/* <td>{row.id}</td> */}
                        <td><Link to={`/user/${row.id}`}><img className={styles.view} src={eye} alt="см." /></Link></td>
                        <td>{row.username}</td>
                        <td>{moment(row.created_at).format('DD.MM.YYYY')}</td>
                        <td>{row.stores_count}</td>
                        <td>{row.email}</td>
                        <td>{row.is_company ? 'Ю' : 'Ф'}</td>
                        {/* <td>{row.tg_username ? row.tg_username : 'нет'}</td> */}
                        <td>{row.is_banned ? 'Да' : 'Нет'}</td>
                        <td>{row.deleted_at ? 'Да' : 'Нет'}</td>
                    </tr>
                )) : null}
            </tbody>
        </table >
    );
}

export default Table;
