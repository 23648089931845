import React from 'react';
import styles from './Loading.module.css';
import botq from '../assets/botq.svg';

const Loading = () => {
    return (
        <div className={`${styles.loadingOverlay} ${styles.show}`}>
            <div className={styles.loaderContainer}>
                <div className={styles.loader}></div>
                <img className={styles.centerImage} src={botq} alt="Center" />
            </div>
        </div>
    );
};

export default Loading;