import axios from "axios";

const api = {
    getOrders: async (page) => {
        try {
            const response = await axios.get(`https://orders.thebotique.ru/api/v1/orders/search?page=${page}&size=10`, {
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("token"),
                // },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    searchOrder: async (order_id) => {
        try {
            const response = await axios.get(`https://orders.thebotique.ru/api/v1/orders/search?search=${order_id}`, {
                // headers: {
                //     Authorization: "Bearer " + localStorage.getItem("token"),
                // },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
};

export default api;