import { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import styles from './Support.module.css';
import ChatCard from "./components/ChatCard";
import api from "./api/api";

function Support() {

    const [chats, setChats] = useState([]);

    useEffect(() => {
        api.getChats().then(r => {
            if (r) {
                setChats(r.data);
            }
        })
    }, [])

    return (
        <>
            <Header />
            <div className={styles.container}>
                <h2>Список обращений в поддержку ({chats.length})</h2>
                {chats ? chats.map((chat, id) => <ChatCard key={id} supportChatData={chat} />) : null}
            </div>
        </>
    );
}

export default Support;
