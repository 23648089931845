import styles from "./NewOrderInfo.module.css";
import arrow from '../../../assets/arrow.svg'
import { useNavigate } from "react-router-dom";

function NewOrderInfo() {

    const nav = useNavigate();

    return (
        <div className={styles.card}>
            <div className={styles.timeInfo}>
                <span>02.02.2024 21.43</span>
            </div>
            <strong>Заказ: №cc4ff82c-c39f-4a9e-98cc-2974942e1ace</strong>
            <div className={styles.details}>
                <span>Сумма: 562</span>
                <span>Количество товаров: 2</span>
                <span>Магазин: #b70dfb37-d34b-4816-a886-368a9f98757f</span>
            </div>
            <div className={styles.buttons}>
                <span onClick={() => { nav('/orders'); }}>Ко всем заказам</span>
                <img className={styles.arrow} src={arrow} alt="" />
            </div>
        </div>
    )
}

export default NewOrderInfo;