import moment from 'moment';
import styles from './Message.module.css';

function Message({ message }) {

    return (
        <>
            <div className={styles.clientMessage}>
                {message.user_message ?
                    <div className={`${styles.message} ${message.is_order_support ? styles.order : null}`}>
                        <span>{moment(message.timestamp).format('DD.MM.YYYY HH:mm')}</span>
                        <span>{message.first_name}: {message.user_message}</span>
                        <span></span>
                    </div>
                    : null}
            </div>
            <div className={styles.botMessage}>
                <div className={styles.message}>
                    <span>{moment(message.timestamp).format('DD.MM.YYYY HH:mm')}</span>
                    <span>{message.bot_response}</span>
                </div>
            </div>
        </>
    );
}

export default Message;
