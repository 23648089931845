import styles from './OrderCard.module.css';
import React, { useState } from 'react';

const OrderCard = ({ order }) => {
    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const [isDeliveryOpen, setIsDeliveryOpen] = useState(false);
    const [isItemsOpen, setIsItemsOpen] = useState(false);
    const [isStatusOpen, setIsStatusOpen] = useState(false);

    const toggleSummary = () => setIsSummaryOpen(!isSummaryOpen);
    const toggleDelivery = () => setIsDeliveryOpen(!isDeliveryOpen);
    const toggleItems = () => setIsItemsOpen(!isItemsOpen);
    const toggleStatus = () => setIsStatusOpen(!isStatusOpen);

    const getDeliveryType = (code) => {
        if (code === 1) {
            return "Самовывоз";
        } else if (code === 3) {
            return "Курьерская";
        } else if (code === 2) {
            return "Boxberry";
        } else if (code === 4) {
            return "Цифровой товар / услуга";
        }
    };


    return (
        <div className={styles.orderCard}>
            <h2>Заказ #{order.order_id}</h2>
            <p>Номер клиента: {order.customer_id}</p>
            <p>Дата создания: {new Date(order.created_at).toLocaleString()}</p>
            {/* Сумма заказа */}
            <div className={styles.section}>
                <h3 className={styles.sectionTitle} onClick={toggleSummary}>Сумма заказа</h3>
                {isSummaryOpen && (
                    <div className={styles.sectionContent}>
                        <p>Общая сумма: {order.total.amount / 100} руб.</p>
                        <p>Сумма товаров: {order.total.goods / 100} руб.</p>
                        <p>Стоимость доставки: {order.total.delivery_cost / 100} руб.</p>
                    </div>
                )}
            </div>

            {/* Доставка */}
            <div className={styles.section}>
                <h3 className={styles.sectionTitle} onClick={toggleDelivery}>Доставка</h3>
                {isDeliveryOpen && (
                    <div className={styles.sectionContent}>
                        <p>Тип доставки: {getDeliveryType(order.delivery.type)}</p>
                        <p>Адрес доставки: {order.delivery.address}</p>
                        <p>Телефон: {order.delivery.phone}</p>
                        <p>Email: {order.delivery.email}</p>
                        <p>Имя клиента: {order.delivery.client_name}</p>
                    </div>
                )}
            </div>

            {/* Товары */}
            <div className={styles.section}>
                <h3 className={styles.sectionTitle} onClick={toggleItems}>Товары</h3>
                {isItemsOpen && (
                    <div className={styles.sectionContent}>
                        {order.options.map(option => (
                            <div key={option.option_id} className={styles.orderItem}>
                                <p>Название: {option.name}</p>
                                <p>Количество: {option.quantity}</p>
                                <p>Цена без скидки: {option.price / 100} руб.</p>
                                <p>Сумма: {option.total_price / 100} руб.</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {/* Статус */}
            <div className={styles.section}>
                <h3 className={styles.sectionTitle} onClick={toggleStatus}>Статус</h3>
                {isStatusOpen && (
                    <div className={styles.sectionContent}>
                        <p>Статус: {order.state.id}</p>
                        <p>Обновлено: {new Date(order.state.updatedAt).toLocaleString()}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default OrderCard;

