import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getSellerStatistics: async () => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/register-statistics/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    getLastSeller: async () => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/last-seller/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    getWeekVisits: async () => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/week-visits/`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    getOrderAnalytics: async () => {
        try {
            return await axios.get(`${baseAPI}/api/v1/business-admin/order-analytics/`, { headers: { Authorization: "Bearer " + localStorage.getItem("token"), }, });
        } catch (error) { }
    },
};

export default api;