import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/shared/Header";
import styles from './User.module.css';
import api from "./api/api";
import StoreAndProducts from "./components/StoreAndProducts";


function Users() {

    const [data, setData] = useState([]);
    const [isBlocked, setIsBlocked] = useState(false);

    const { userID } = useParams();

    useEffect(() => {
        api.getUser(userID).then(r => {
            setData(r.data);

            console.log(r.data);

            setIsBlocked(r.data.seller.is_banned);
        })
    }, []);

    function changeAccess() {
        if (isBlocked) {
            api.unBlockUser(userID).then(r => {
                if (r) {
                    setIsBlocked(prev => false);
                }
            })
        } else {
            api.blockUser(userID).then(r => {
                if (r) {
                    setIsBlocked(prev => true);
                }
            });
        }
    }
    return (
        <>
            <Header />
            <div className={styles.container}>
                <h2>Информация о пользователе {data?.seller?.username}</h2>
                <div className={styles.info}>
                    <div className={styles.sellerCard}>
                        <strong>Карточка продавца</strong>
                        {/* <span>Имя пользователя: <strong>{data?.seller?.username}</strong></span> */}
                        <span>Дата регистрации: <strong>{moment(data?.seller?.created_at).format('DD.MM.YYYY')}</strong></span>
                        <span>Имя: <strong>{data?.seller?.first_name} {data?.seller?.last_name}</strong></span>
                        <span>Email: <strong>{data?.seller?.email}</strong></span>
                        <span>Телеграмм: <strong>{data?.seller?.tg_username}</strong></span>
                        <span>Телефон: <strong>{data?.seller?.tel}</strong></span>
                        <span>Тип: <strong>{data?.seller?.is_company ? 'Юридическое лицо' : 'Физическое лицо'}</strong></span>
                        <span>Организация: <strong>{data?.seller?.company_name ? data?.seller?.company_name : 'Нет информации'}</strong></span>
                        <span>Дата удаления: <strong>{data?.seller?.deleted_at ? moment(data?.seller?.deleted_at).format('DD.MM.YYYY') : 'Нет'}</strong></span>
                    </div>
                    <div className={styles.subscriptionCard}>
                        <strong>Информация о подписке</strong>
                        <span>Уровень: <strong>{data?.subscription?.subscription_type === 0 ? 'Пробный' : data?.subscription?.subscription_type === 1 ? 'Стандарт' : 'Индивидуальный'}</strong></span>
                        <span>Дата продления: <strong>{data?.subscription?.renewal_date ? moment(data?.subscription?.renewal_date).format('DD.MM.YYYY') : 'Нет'}</strong></span>
                        <span>Статус: <strong>{data?.subscription?.subscription_status ? 'Активна' : 'Отменена'}</strong></span>
                        <span>Куплено периодов: <strong>{data?.subscription?.periods_count}</strong></span>
                    </div>
                    <div className={styles.ordersCard}>
                        <strong>Информация о заказах</strong>
                        <span>Количество: <strong>?</strong></span>
                        <span>Покупателей: <strong>?</strong></span>
                        <span>Сумма заказов: <strong>?</strong></span>
                    </div>
                    <div className={styles.actionsCard}>
                        <strong>Ограничения</strong>
                        <span>Доступ к сервису: <strong>{isBlocked ? 'Ограничен' : 'Есть'}</strong></span>
                        <span className={styles.block} onClick={changeAccess}>{isBlocked ? 'Разблокировать' : 'Заблокировать'}</span>
                    </div>
                </div>
                <div className={styles.storesContainer}>
                    <h2>Информация о магазинах</h2>
                    <div className={styles.stores}>
                        {data.stores && <StoreAndProducts stores={data?.stores} products={data?.products} />}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Users;
