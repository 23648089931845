import styles from './ChatCard.module.css';
import arrow from '../../../assets/arrow.svg'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function ChatCard({ supportChatData }) {

    const nav = useNavigate();

    return (
        <div className={`${styles.card} ${supportChatData.is_order ? styles.cardOrder : ''}`}>
            <div className={styles.details}>
                <span>Пользователь: {supportChatData.username}</span>
                <span>Последнее сообщение: {moment(supportChatData.last_message_timestamp).format('DD.MM.YYYY HH:MM')}</span>
                <span>Количество сообщений: {supportChatData.message_count}</span>
                <span>Текст последнего сообщения: {supportChatData.last_message ? supportChatData.last_message : 'Ответ администратора'}</span>
            </div>
            <div className={styles.buttons}>
                <span onClick={() => { nav(`/support/chat/${supportChatData.chat_id}`); }}>К чату</span>
                <img className={styles.arrow} src={arrow} alt="" />
            </div>
        </div>
    );
}

export default ChatCard;
