import styles from './Input.module.css'

function Input({ type, placeholder, value, onChange, required, handleEnter }) {

    return (
        <>
            <input
                className={styles.input}
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={onChange}
                required={required}
                onKeyUp={({ key }) => { if (key === 'Enter') { handleEnter(); } }}
            />
        </>
    )
}

export default Input;