import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";

function Header() {
    const nav = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.reload();
    };

    return (
        <header>
            <div className={styles.header}>
                <span><strong>Botique</strong>: Управление проектом</span>

                <ul className={styles.menu}>
                    <li onClick={() => { nav('/'); }}>Главная</li>
                    <li onClick={() => { nav('/support'); }}>Обращения в поддержку</li>
                    <li onClick={() => { nav('/orders'); }} >Список заказов</li>
                    <li onClick={() => { nav('/users'); }}>Список пользователей</li>
                    <li onClick={handleLogout}>Выйти</li>
                </ul>
            </div>
        </header >
    )
}

export default Header;