import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';
import api from '../api/api';

function SupportChart() {

    const chartRef = useRef(null);

    const [data, setData] = useState([]);

    function getPastDates() {
        const currentDate = new Date();
        const pastDates = [];

        for (let i = 6; i >= 0; i--) {
            const date = new Date(currentDate);
            date.setDate(currentDate.getDate() - i);
            pastDates.push(date.toISOString().split('T')[0]);
        }

        return pastDates;
    }

    useEffect(() => {
        api.getWeekVisits().then(r => {
            if (r) {
                setData(r.data);
            }
        })
    }, []);

    useEffect(() => {
        const ctx = chartRef.current.getContext('2d');

        const sprtChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: getPastDates(),
                datasets: [{
                    label: 'Переходов в поддержку',
                    data: data,
                    borderWidth: 1
                }]
            },
        });

        return () => {
            sprtChart.destroy();
        };
    }, [data]);

    return <canvas ref={chartRef} />;
}

export default SupportChart;
