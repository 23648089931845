import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Input from '../../components/shared/Input';
import styles from './Login.module.css';
import api from './api/api';
import Loading from '../../components/Loading';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const nav = useNavigate();

    const handleSubmit = (e) => {
        // e.preventDefault();
        setIsLoading(true);
        api.handleLogin(username, password).then((status) => {
            setIsLoading(false);
            if (status) {
                nav('/');
            } else {
                setIsError(true);
            }
        });
    };

    return (
        <>
            <div className={styles.container}>
                <form className={`${styles.authForm} ${isError ? styles.error : ''}`}>
                    <strong>Авторизация</strong>
                    <Input
                        type="text"
                        placeholder={'Имя пользователя'}
                        value={username}
                        onChange={(e) => { setUsername(e.target.value); }}
                        required={true}
                    />
                    <Input
                        type="password"
                        placeholder={'Пароль'}
                        value={password}
                        onChange={(e) => { setPassword(e.target.value); }}
                        required={true}
                        handleEnter={handleSubmit}
                    />
                    <span onClick={handleSubmit}>Войти</span>
                </form>
            </div>
            {isLoading ? <Loading /> : null}
        </>
    );
}

export default Login;
