import styles from "./NewSellerInfo.module.css";
import arrow from '../../../assets/arrow.svg'
import api from "../api/api";
import { useEffect, useState } from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

function NewSellerInfo() {
    const nav = useNavigate();

    const [data, setData] = useState({
        created_at: "",
        username: "",
        first_name: "",
        last_name: "",
        subscription_type: 0,
        stores_count: 0
    });

    useEffect(() => {
        api.getLastSeller().then((r) => {
            if (r) {
                setData(r.data);
            }
        })
    }, []);

    return (
        <div className={styles.card}>
            <div className={styles.timeInfo}>
                <span>{moment(data.created_at).format('DD.MM.YYYY HH:MM')}</span>
            </div>
            <strong>Встречаем нового пользователя: {data.username}!</strong>
            <div className={styles.details}>
                <span>Имя: {data.first_name + ' ' + data.last_name}</span>
                <span>Тариф: {data.subscription_type === 0 ? 'Пробный' : 'Платный'}</span>
                <span>Создано магазинов: {data.stores_count}</span>
            </div>
            <div className={styles.buttons}>
                <span onClick={() => { nav('/users'); }}>Ко всем продавцам</span>
                <img className={styles.arrow} src={arrow} alt="" />
            </div>
        </div>
    )
}

export default NewSellerInfo;