import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getChat: async (chatID) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/messages?chat_id=${chatID}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    sendMessage: async (chatID, message, username) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/send-message/`, { chat_id: chatID, message: message, username: username }, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
};

export default api;