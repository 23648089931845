import axios from "axios";
import baseAPI from "../../../api/api.config";

const api = {
    getUser: async (id) => {
        try {
            const response = await axios.get(`${baseAPI}/api/v1/admin/user?seller_id=${id}`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    blockUser: async (id) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/block-user/`, { seller_id: id}, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
    unBlockUser: async (id) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/admin/unblock-user/`, { seller_id: id}, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    'Content-Type': 'application/json',
                },
            });
            return response;

        } catch (error) {
            return null;
        }
    },
};

export default api;