import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/shared/Header";
import styles from './SupportChat.module.css';
import api from "./api/api";
import Message from "./components/Message";

function SupportChat() {

    const { chatID } = useParams();

    const bottomRef = useRef(null);

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');

    useEffect(() => {
        api.getChat(chatID).then(r => {
            if (r) {
                setMessages(r.data);
            }
        })
    }, [chatID]);

    useEffect(() => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    function sendMessage() {
        if (message !== '' & message.length > 1) {
            api.sendMessage(chatID, message, messages[0]?.username).then(r => {
                if (r) {
                    setMessages(prev => ([
                        ...prev,
                        { timestamp: new Date(), bot_response: message, user_message: '', username: messages[0]?.username }
                    ]));
                    setMessage('');
                }
            });
        }
    }

    return (
        <>
            <Header />
            <div className={styles.container}>
                <h2>Чат с пользователем {messages[0]?.username}</h2>
                <div className={styles.chat}>
                    {messages.map((message, id) => <Message key={id} message={message} />)}
                    <div ref={bottomRef}></div>
                </div>
                <div className={styles.sendArea}>
                    <input type="text" placeholder="Написать сообщение" value={message} onChange={(e) => { setMessage(e.target.value); }} />
                    <span onClick={sendMessage}>Отправить</span>
                </div>
            </div>
        </>
    );
}

export default SupportChat;
