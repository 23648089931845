import styles from './ScoresTable.module.css'
import api from '../api/api';
import { useEffect, useState } from 'react';

function ScoresTable() {

    const [statistics, setStatistics] = useState({ seller_count: 0, subscription_count: 0, customers_count: 0, orders_count: 0, total_amount: 0 });

    useEffect(() => {
        api.getSellerStatistics().then(r => {
            if (r) {
                setStatistics(prev => ({
                    ...prev,
                    seller_count: r.data.seller_count,
                    subscription_count: r.data.subscription_count,
                }));
            }
        });
        api.getOrderAnalytics().then(r => {
            if (r) {
                setStatistics(prev => ({
                    ...prev,
                    customers_count: r.data.customers_count,
                    orders_count: r.data.orders_count,
                    total_amount: r.data.total_amount,
                }));
            }
        });
    }, []);
    return (
        <div className={styles.scoresContainer}>
            <div className={styles.data}>
                <span><strong>Статистика сервиса</strong></span>
                <span><strong>Количество продавцов:</strong> {statistics.seller_count}</span>
                <hr />
                <span><strong>Количество покупателей:</strong> {statistics.customers_count}</span>
                <hr />
                <span><strong>Количество заказов:</strong> {statistics.orders_count}</span>
                <hr />
                <span><strong>Сумма заказов:</strong> {statistics.total_amount / 100}</span>
                <hr />
                <span><strong>Активных подписок:</strong> {statistics.subscription_count}</span>
                <hr />
            </div>
        </div>
    );
}

export default ScoresTable;
