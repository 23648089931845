import { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import styles from './Orders.module.css';
import api from "./api/api";
import OrderCard from "./components/OrderCard";
import Pagination from "./components/Pagination";

function Orders() {

    const [orders, setOrders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        api.getOrders(1).then(r => {
            setOrders(r.data.orders);
            setPage(r.data.page);
            setTotalPages(r.data.total_pages);
        });
    }, []);

    const handlePageChange = (pageNumber) => {
        api.getOrders(pageNumber).then(r => {
            setOrders(r.data.orders);
            setPage(r.data.page);
            setTotalPages(r.data.total_pages);
        });
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 4) {
            api.searchOrder(e.target.value).then(r => {
                setOrders(r.data.orders);
                setPage(r.data.page);
                setTotalPages(r.data.total_pages);
            });
        } else if (e.target.value.length === 0) {
            api.getOrders(1).then(r => {
                setOrders(r.data.orders);
                setPage(r.data.page);
                setTotalPages(r.data.total_pages);
            });
        }
    };

    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.orders}>
                    <div className={styles.tools}>
                        <div className={styles.searchBox}>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                placeholder="Поиск по номеру заказа"
                            />
                        </div>
                        <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
                    </div>
                    {orders.map((order, index) => (<div key={index}>
                        <OrderCard order={order} />
                    </div>))}
                    <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
                </div>
            </div>
        </>
    );
}

export default Orders;
