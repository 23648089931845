import { useEffect, useState } from "react";
import Header from "../../components/shared/Header";
import styles from './Users.module.css';
import api from "./api/api";
import Table from "./components/Table";
import Pagination from "../Orders/components/Pagination";


function Users() {

    const [data, setData] = useState([]);

    const [totalPages, setTotalPages] = useState(1);
    const [page, setPage] = useState(1);

    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        api.getUsers(1).then(r => {
            setData(r.data.users);
            setPage(r.data.current_page);
            setTotalPages(r.data.total_pages);
            setIsLoading(false);
        });
    }, []);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        if (e.target.value.length > 2) {
            setIsLoading(true);
            api.searchUsers(e.target.value).then(r => {
                setData(r.data.users);
                setPage(r.data.current_page);
                setTotalPages(r.data.total_pages);
                setIsLoading(false);
            });
        } else if (e.target.value.length === 0) {
            setIsLoading(true);
            api.getUsers(1).then(r => {
                setData(r.data.users);
                setPage(r.data.current_page);
                setTotalPages(r.data.total_pages);
                setIsLoading(false);
            });
        }
    };

    const handlePageChange = (pageNumber) => {
        setIsLoading(true);
        api.getUsers(pageNumber).then(r => {
            setData(r.data.users);
            setPage(r.data.current_page);
            setTotalPages(r.data.total_pages);
            setIsLoading(false);
        });
    };

    return (
        <>
            <Header />
            <div className={styles.container}>
                <h2>Список пользователей</h2>
                <div className={styles.tools}>
                    <div className={styles.searchBox}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="Поиск по username или email"
                        />
                    </div>
                    <Pagination totalPages={totalPages} currentPage={page} onPageChange={handlePageChange} />
                </div>
                <Table data={data} />
                {isLoading && <div className={styles.loader}></div>}
            </div>
        </>
    );
}

export default Users;
